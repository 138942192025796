<template>
  <div class="login">
    <van-cell title="登录" to="/account/login" />
    <img src="http://fresh.huruqing.cn/img/logo.adec77d3.png" alt="" />
    <van-cell-group>
      <van-field v-model="sms" placeholder="请输入手机号">
        <template #button>
          <input
            type="button"
            value="获取短信验证码"
            class="f12 bg-fff"
            style="border: none"
            @click="demo()"
          />
        </template>
      </van-field>
      <van-field v-model="value2" placeholder="请输入验证码" />
      <van-field v-model="value3" placeholder="请输入密码" />
    </van-cell-group>
    <van-button
      type="default"
      style="
        width: 82%;
        margin: 30px auto;
        display: block;
        color: #fff;
        background-color: #b13d38;
      "
      >现在注册</van-button
    >
  </div>
</template>
<script>
</script>

<style lang="less" scoped>
.login {
  .van-cell {
    text-align: right;
    padding-right: 28px;
    border-bottom: 1px solid #f5f6f7;
    font-size: 14px;
  }
  img {
    width: 40%;
    display: block;
    margin: 78px auto;
  }
  .van-cell-group {
    width: 88%;
    margin: 0 auto;
  }
  .van-field {
    ::-webkit-input-placeholder {
      color: #949494;
    }
  }
}
</style>
<script>
export default {
  methods: {
    demo() {
      alert(111);
    },
  },
};
</script>
